<template>
  <div class="bundle-swiper">
    <BundleSwiper />
  </div>
</template>

<script>
import BundleSwiper from '@/components/bnn/BundleSwiper'
export default {
  components: {
    BundleSwiper,
  },
}
</script>

<style lang="stylus" scoped>
.bundle-swiper {
  margin-top: 150px;
  display: flex
  justify-content: center
}
</style>
